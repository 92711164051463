import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 97 33">
      <path
        fill="#18223F"
        fillRule="evenodd"
        d="M54.572 10.872c1.453-1.75 3.588-2.613 6.365-2.613 2.488 0 4.55.838 6.168 2.518 1.653 1.685 2.465 4.003 2.465 6.92V31.578H63.353V18.407c0-1.436-.386-2.488-1.122-3.197l.17-.178v.001l-.17.177-.004-.003c-.744-.745-1.742-1.127-3.02-1.127-1.424 0-2.54.44-3.373 1.302-.827.854-1.262 2.158-1.262 3.958v12.236H48.355V8.88H54.572v1.991zM90.782 0H97V31.577H90.783v-2.192c-1.708 1.88-4.031 2.813-6.942 2.813-3.059 0-5.681-1.155-7.856-3.452l-.002-.002c-2.145-2.328-3.216-5.171-3.216-8.515 0-3.344 1.071-6.174 3.217-8.472 2.174-2.327 4.797-3.498 7.857-3.498 2.911 0 5.234.934 6.942 2.813V0zm-1.658 15.918l-.002-.002c-1.103-1.133-2.502-1.702-4.216-1.702-1.714 0-3.13.57-4.263 1.704-1.102 1.103-1.659 2.532-1.659 4.31 0 1.777.556 3.221 1.656 4.353 1.134 1.106 2.55 1.662 4.266 1.662 1.714 0 3.113-.555 4.216-1.657 1.103-1.133 1.66-2.579 1.66-4.357 0-1.78-.557-3.208-1.658-4.31z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#18213E"
        fillRule="evenodd"
        d="M19.041 6.355H6.617v6.877H18.686v6.355H6.617v11.99H0V0h19.041V6.355zM37.387 8.88H43.604v22.696H37.387v-1.992c-1.453 1.75-3.588 2.613-6.365 2.613-2.487 0-4.564-.837-6.21-2.516l-.003-.002c-1.622-1.685-2.42-4.003-2.42-6.92V8.88H28.606v13.168c0 1.434.385 2.502 1.123 3.242.744.717 1.743 1.085 3.023 1.085 1.424 0 2.54-.44 3.373-1.301.827-.855 1.262-2.159 1.262-3.958V8.88z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
