import React, { useEffect } from "react";
import firebase from "../../../firebase";

//comps

//firebase
const db = firebase.database();

const Spinner = (props) => {
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.random() * (max - min + 1) + min;
  }

  const subTrade = () => {
    let gain = randomIntFromInterval(-100, 100);
    const ref = db.ref("trades");
    const newTrade = ref.push();
    let date = new Date();

    newTrade.set({
      gain: gain.toFixed(2),
      direction: Math.random() < 0.5 ? "long" : "short",
      total: 15000,
      date: firebase.database.ServerValue.TIMESTAMP,
    });
  };

  useEffect(() => {
    const ref = db.ref("trades");
  }, []);

  return (
    <button className="line" onClick={() => subTrade()}>
      Trade
    </button>
  );
};

export default Spinner;
