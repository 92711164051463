import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//sections
import FullScreenCenter from "../../sections/FullScreenCenter";

//components
import Auth from "../../comps/Modal/Auth";

const Home = (props) => {
  const history = useHistory();
  const user = props.user;
  if (user) history.push("/account");

  return (
    <div className="">
      <FullScreenCenter>
        <Auth />
      </FullScreenCenter>
    </div>
  );
};

export default Home;
