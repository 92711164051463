import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import "./style.css";

//comps
import Trade from "./Trade";

//firebase
const db = firebase.database();

const Spinner = (props) => {
  const [trades, setTrades] = useState();
  // const trades = [1, 2, 1];

  useEffect(() => {
    const ref = db.ref("trades").orderByValue().limitToLast(20);
    ref.on("value", (snapshot) => {
      console.log(snapshot.val());
      setTrades(snapshot.val());
    });
    return () => ref.off();
  }, []);

  // useEffect(() => {
  //   db.ref("trades")
  //     .get()
  //     .then(function (snapshot) {
  //       if (snapshot.exists()) {
  //         console.log(snapshot.val());
  //       } else {
  //         console.log("No data available");
  //       }
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // }, []);

  let date = new Date()
  console.log(date);
  return (
    <div className="trade-container">
      <h5>Trades</h5>
      <div className="trades-wrapper">
        {trades ? (
          Object.keys(trades).map((trade) => <Trade data={trades.[trade]} /> )
        ) : (
          <span>No Trades</span>
        )}
        <button className="fog">Load More</button>
      </div>
    </div>
  );
};

export default Spinner;
