import React from "react";
import "./style.css";

//comps
import Item from "./Item";
import Chart from "./Chart";

const Spinner = (props) => {
  //sharp ratio is  x * 25 out of 100
  const items = [
    {
      value: 48,
      scale: 100,
      min: 33,
      title: "Win Rate",
    },
    {
      value: 54,
      scale: 100,
      min: 50,
      title: "% Long",
    },
    {
      value: 1.45,
      scale: 4,
      min: 1,
      title: "Sharp",
    },
  ];

  return (
    <div className="chart-container glass-back">
      <Chart />
      <div className="chart-items-container">
        {items.map((item) => (
          <Item data={item} />
        ))}
      </div>
    </div>
  );
};

export default Spinner;
