import React from "react";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";

import "./style.css";

const Gauge = (props) => {
  const value = props.data.value,
    lowLevel = props.data.min,
    min = 0,
    max = props.data.scale,
    manual = -(0.5 - props.data.min / max) * 280.8;

  console.log(
    "should be: " +
      props.data.min +
      ", is: " +
      manual +
      " (" +
      (0.5 - props.data.min / max) * 100 +
      "% of 280.8)"
  );

  const backgroundArc = arc()
    .innerRadius(0.8)
    .outerRadius(1)
    .startAngle(-Math.PI * 0.78)
    .endAngle(Math.PI * 0.78)
    .cornerRadius(1)();
  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);
  const minLevel = percentScale(lowLevel);
  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);
  const angle = angleScale(percent);
  const minAngle = angleScale(minLevel);
  const filledArc = arc()
    .innerRadius(0.8)
    .outerRadius(1)
    .startAngle(-Math.PI * 0.78)
    .endAngle(angle)
    .cornerRadius(1)();

  // const filledArc2 = arc()
  //   .innerRadius(1.1)
  //   .outerRadius(0.7)
  //   .startAngle(minAngle * 0.78 - 0.1)
  //   .endAngle(minAngle)
  //   .cornerRadius(0.5)();
  return (
    <div className="gauge-container">
      <svg
        style={{ overflow: "visible" }}
        width="100%"
        viewBox={[-1, -1, 2, 2].join(" ")}
      >
        <path d={backgroundArc} fill="rgba(24, 34, 63, 0.1)" />
        <path d={filledArc} fill="#18223F" />
      </svg>
      <div
        className="marker-wrapper"
        style={{ transform: `rotate(${manual}deg)` }}
      >
        <div className="marker-line" />
      </div>

      <h5>{value}</h5>
      <p>{props.data.title}</p>
    </div>
  );
};

export default Gauge;

// <path classNAme d={filledArc2} fill="red" />
