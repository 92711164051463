import React, { useState } from "react";
import { useHistory } from "react-router-dom";

//firebase
import firebase from "../../../../firebase";

// components
import Spinner from "../../../Spinner";

const Auth = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, SetUser] = useState({ email: "", password: "" });
  const history = useHistory();

  const signInUser = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setLoading(true);

    firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password)
      .then(() => {
        setLoading(false);
        setErrorMessage("");
        history.push("/account");
      })
      .catch((error) => {
        console.log(error.code, ": ", error.message);
        handleErrorMessage(error.message);
      });
  };

  const handleErrorMessage = (message) => {
    setLoading(false);
    setErrorMessage(message);
  };
  return (
    <form className="signup-form flex-col" onSubmit={(e) => signInUser(e)}>
      <input
        className="auth-email"
        id="email"
        name="email"
        type="email"
        label="Email"
        placeholder="email"
        value={user.email}
        onChange={(e) =>
          SetUser({
            email: e.target.value,
            password: user.password,
          })
        }
      />
      <input
        className="auth-pass"
        id="password-signup"
        name="password"
        type="password"
        label="Password"
        placeholder="Password"
        value={user.password}
        onChange={(e) =>
          SetUser({
            email: user.email,
            password: e.target.value,
          })
        }
      />
      {errorMessage ? (
        <div className="auth-error-message">{errorMessage}</div>
      ) : null}
      <div className="auth-modal-submit-wrapper flex-just-btw">
        <div className="flex-just-lft">
          <button
            onClick={(e) => e.preventDefault()}
            className="auth-forgot-botton"
          >
            Forgot?
          </button>
        </div>
        <button submit className="blue-button">
          {!loading ? (
            "Submit"
          ) : (
            <div className="flex-align-cent">
              <Spinner button={true} size={"24px"} />
              <span style={{ marginLeft: "12px" }}>Loading</span>
            </div>
          )}
        </button>
      </div>
    </form>
  );
};

export default Auth;
