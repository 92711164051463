import React from "react";
import "./style.css";

const Spinner = (props) => {
  let gain = (props.data.gain / props.data.total) * 100;
  let dateprop = props.data.date;
  // const date = dateprop ? dateprop.toDate().toDateString() : "na";
  // const time = dateprop.toDate().toLocaleTimeString("en-US");

  return (
    <div className="trade-wrapper glass-back flex-align-cent flex-just-btw">
      <div className=" flex-just-btw flex-align-cent">
        <div className="trade-shot flex-cent">
          <svg
            viewBox="0 0 24 24"
            style={{
              transform: `scaleY(${props.data.direction == "long" ? 1 : -1})`,
            }}
          >
            <path
              fill="currentColor"
              d="M3.5,18.5L9.5,12.5L13.5,16.5L22,6.92L20.59,5.5L13.5,13.5L9.5,9.5L2,17L3.5,18.5Z"
            />
          </svg>
        </div>
        <div
          className="trade-indicator"
          style={{ background: props.data.gain > 0 ? "#27ae60" : "#EB5757" }}
        />
        <div className="trade-tex-wrap">
          <h4>{`${props.data.gain > 0 ? "+" : "-"} $${Math.abs(
            props.data.gain
          ).toFixed(2)}`}</h4>
          <p>{`${props.data.gain > 0 ? "+" : "-"} ${Math.abs(gain).toFixed(
            2
          )}%`}</p>
        </div>
      </div>
      <div className="trade-tex-wrap" style={{ textAlign: "right" }}>
        <h4>10:30 pm</h4>
        <p>01/01/21</p>
      </div>
      <div className="trade-new-tag">New</div>
    </div>
  );
};

export default Spinner;

// <div className="direction-wrap flex-cent">
//   <svg viewBox="0 0 24 24">
//     <path
//       fill="currentColor"
//       d="M15,20H9V12H4.16L12,4.16L19.84,12H15V20Z"
//     />
//   </svg>
// </div>
//
