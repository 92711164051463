import React, { useState, useEffect, useRef } from "react";
import { NavHashLink } from "react-router-hash-link";
import "./style.css";

//comps
import Test from "./TradeTest";

//media
import Menu from "../../media/icons/Menu";
import Logo from "../../media/logo/Logo.js";

//functions
import useDocumentScrollThrottled from "./useDocumentScrollThrottled";
import useWindowDimensions from "../../functions/useWindowDimensions.js";

const Comp = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [shouldShowShadow, setShouldShowShadow] = useState(false);
  const [underline, setUnderline] = useState(0);
  const [active, setActive] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);

  const { height, width } = useWindowDimensions();

  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 0;

  useDocumentScrollThrottled((callbackData) => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setShouldShowShadow(currentScrollTop > 2);
    setTimeout(() => {
      setShouldHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  const links = props.user
    ? [
        { name: "Dashboard", link: "#top" },
        { name: "Messages", link: "#top" },
        { name: "Account", link: "#top" },
        { name: "Sign out", link: "/logout" },
      ]
    : [];

  return (
    <React.Fragment>
      <header
        className={`${
          mobileMenu && width < 700 ? "mobile-menue-active" : ""
        } flex-just-cent ${
          shouldShowShadow && !mobileMenu ? "header-shadow" : ""
        } ${shouldHideHeader && !mobileMenu ? "header-hidden" : ""}`}
      >
        <div className="flex-align-cent flex-just-btw">
          <div className="header-tem " onClick={() => setMobileMenu(false)}>
            <NavHashLink
              className="header-logo flex-align-cent"
              to="#top"
              smooth
            >
              <Logo />
            </NavHashLink>
          </div>

          <div className="header-tem">
            <div className="header-button-wrap flex-align-cent flex-just-rit">
              <nav className="header-nav-wrap">
                <ul className="header-nav flex-align-cent flex-just-cent">
                  {links.map((item, i) => {
                    return (
                      <li key={`nav${i}-key`}>
                        <NavHashLink to={item.link} smooth className="nav-text">
                          {item.name}
                        </NavHashLink>{" "}
                      </li>
                    );
                  })}
                </ul>
              </nav>
              <a className=" extra-button" href="mailto:matt@mwolfe.io">
                <button className="header-button line">Contact</button>
              </a>
              <NavHashLink to="/contact#top">
                <button className="header-button primary primary-button">
                  Download
                </button>
              </NavHashLink>
            </div>
          </div>
          <div
            id="mobile-menu-button"
            onClick={(e) => setMobileMenu(!mobileMenu)}
          >
            <Menu />
          </div>
        </div>
        <nav className="mobile-menu">
          <ul style={{ padding: 0 }} onClick={() => setMobileMenu(false)}>
            {links.map((link) => (
              <li className="">
                <NavHashLink to={link.link} smooth>
                  {link.name}
                </NavHashLink>
              </li>
            ))}
          </ul>

          <div className="header-foot  flex-align-cent flex-just-cent">
            <NavHashLink
              to="/contact#top"
              smooth
              onClick={() => setMobileMenu(false)}
            >
              <button className="primary">Download App</button>
            </NavHashLink>
            <Test />
          </div>
        </nav>
      </header>
      <div
        className={`mobile-menu-wrap ${
          mobileMenu && width < 700 ? "mobile-menue-active" : ""
        }`}
        onClick={(e) => setMobileMenu(false)}
      />
    </React.Fragment>
  );
};

export default Comp;

// <li>
//   <NavHashLink to="#top " className="nav-text" smooth>
//     Home
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#services" className="nav-text" smooth>
//     Services
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#products" className="nav-text" smooth>
//     Products
//   </NavHashLink>
// </li>
// <li>
//   <NavHashLink to="#testimonials" className="nav-text" smooth>
//     Testimonials
//   </NavHashLink>
// </li>
