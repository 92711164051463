import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//components
import Chart from "../../comps/Chart";
import Trades from "../../comps/Trades";

const Home = (props) => {
  //Authentication
  const history = useHistory();
  const user = props.user;
  if (!user) history.push("/authentication");

  return (
    <section>
      <div className="account-wrapper">
        <Chart />
        <Trades />
      </div>
    </section>
  );
};

export default Home;
