import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//sections
import FullScreenCenter from "../../sections/FullScreenCenter";

//components
import Landing from "../../sections/Landing";

const Home = (props) => {
  const history = useHistory();
  const user = props.user;
  if (user) history.push("/account");

  return (
    <FullScreenCenter>
      <Landing />
    </FullScreenCenter>
  );
};

export default Home;
