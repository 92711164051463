import React from "react";
import { useHistory } from "react-router-dom";
import "./style.css";

//firebase
import firebase from "../../firebase";

//sections
import FullScreenCenter from "../../sections/FullScreenCenter";

//components
import Spinner from "../../comps/Spinner";

const Home = (props) => {
  const user = props.user;
  const history = useHistory();

  if (user) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
        // props.close();
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    history.push("/");
  }

  return (
    <div className="">
      <FullScreenCenter>
        <Spinner size={"32px"} />
      </FullScreenCenter>
    </div>
  );
};

export default Home;
