import React from "react";

const Comp = () => {
  return (
    <svg viewBox="0 0 32 32">
      <rect width="24" height="3" x="4" y="9.5" fill="#000" rx="1.5"></rect>
      <rect width="16" height="3" x="12" y="19.5" fill="#000" rx="1.5"></rect>
    </svg>
  );
};

export default Comp;
