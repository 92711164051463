import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

//firebase
import firebase from "./firebase";

//comps
import Header from "./comps/Header";
import Home from "./pages/Home";
import Account from "./pages/Account";
import Authentication from "./pages/Authentication";
import LogOut from "./pages/LogOut";
import Footer from "./comps/Footer";
import Modal from "./comps/Modal";

//style
import "./global.css";
import "./style.css";
import "./theme.css";
import "./fonts.css";

//media
import Back from "./media/videos/back.mp4";

const App = () => {
  const [previousLocation, setPreviousLocation] = useState();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  //authentication
  firebase.auth().onAuthStateChanged(function (firebaseUser) {
    if (firebaseUser) {
      // console.log("USER: ", firebaseUser ? true : false);
      console.log(firebaseUser.getIdToken());
      setUser(firebaseUser);
    } else {
      // console.log("USER: ", firebaseUser ? true : false);
      setUser(null);
    }
  });

  //Modals
  let location = useLocation();
  useEffect(() => {
    if (!(location.state && location.state.modal)) {
      setPreviousLocation(location);
    }
  }, [location]);
  const isModal =
    location.state && location.state.modal && previousLocation !== location
      ? true
      : false;

  return (
    <div className="App">
      <video
        id="BgVideo"
        className="background-video"
        autoPlay
        muted
        loop
        playsInline
        id="bg-video-1"
        src={Back}
      />
      <Header user={user} />
      <div className="content-wrapper">
        <Switch location={isModal ? previousLocation : location}>
          <Route
            exact
            path="/"
            render={(props) => <Home {...props} user={user} />}
          />
          <Route
            exact
            path="/account"
            render={(props) => <Account {...props} user={user} />}
          />
          <Route
            path="/authentication"
            render={(props) => <Authentication {...props} user={user} />}
          />
          <Route
            path="/logout"
            render={(props) => <LogOut {...props} user={user} />}
          />
        </Switch>
      </div>
      {isModal ? (
        <Route
          path="/modal/:id"
          render={(props) => <Modal {...props} user={user} />}
        />
      ) : null}
    </div>
  );
};

export default App;
