import React from "react";
import "./style.css";

import Gauge from "./Gauge";

const Spinner = (props) => {
  return (
    <div className="chart-item-wrapper flex-cent">
      <Gauge data={props.data} />
    </div>
  );
};

export default Spinner;

// <div>
//   <h2>{props.data.highlight}</h2>
//   <h6>{props.data.title}</h6>
// </div>
//

// <div className="chart-item-chart-wrap">
//   {" "}
//   <div className="chart-item-chart-inner" />
// </div>

// <div className="gauge-wrapper">
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 60">
//     <path
//       fill="#6fcf97"
//       d="M53.702 57.745c1.294 1.565 3.63 1.797 5.046.341a34 34 0 10-48.788-.049c1.414 1.46 3.75 1.231 5.047-.331 1.298-1.563 1.06-3.867-.31-5.367a26.645 26.645 0 1139.325.04c-1.373 1.497-1.615 3.8-.32 5.366z"
//     ></path>
//   </svg>
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 60">
//     <path
//       fill="#F2C94C"
//       d="M53.702 57.745c1.294 1.565 3.63 1.797 5.046.341a34 34 0 10-48.788-.049c1.414 1.46 3.75 1.231 5.047-.331 1.298-1.563 1.06-3.867-.31-5.367a26.645 26.645 0 1139.325.04c-1.373 1.497-1.615 3.8-.32 5.366z"
//     ></path>
//   </svg>
//   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 60">
//     <path
//       fill="#EB5757"
//       d="M53.702 57.745c1.294 1.565 3.63 1.797 5.046.341a34 34 0 10-48.788-.049c1.414 1.46 3.75 1.231 5.047-.331 1.298-1.563 1.06-3.867-.31-5.367a26.645 26.645 0 1139.325.04c-1.373 1.497-1.615 3.8-.32 5.366z"
//     ></path>
//   </svg>
// </div>
//
