import React from "react";
import { withRouter, Route, Switch, useHistory } from "react-router-dom";
import "./style.css";

//components
import Auth from "./Auth";

const Modal = (props) => {
  const history = useHistory();
  const close = () => history.goBack();

  const user = props.user;

  return (
    <div
      role="button"
      className="modal-wrapper flex-align-cent flex-just-cent "
      onMouseDown={() => close()}
    >
      <div
        role="button"
        className="modal-container glass-back"
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Switch>
          <Route
            path="/modal/authentication"
            render={(props) => <Auth {...props} user={user} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(Modal);
