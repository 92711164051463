import React, { useState } from "react";
import "./style.css";

const Landing = (props) => {
  return (
    <section className="flex-align-cent flex-just-cent landing-wrap">
      {props.children}
    </section>
  );
};

export default Landing;
